import { useState } from 'react';
import { config } from '../config'

export function useSubmitResume2() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submitResume = async (resumeData: any): Promise<Blob | null> => {
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch(`${config.apiBaseUrl}/resumes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(resumeData),
      });

      if (!response.ok) {
        throw new Error('Failed to generate resume');
      }

      const pdfBlob = await response.blob();
      return pdfBlob;
    } catch (err: any) {
      setError(err.message);
      return null;
    } finally {
      setIsSubmitting(false);
    }
  };

  return { submitResume, isSubmitting, error, setError };
}

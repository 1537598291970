import React from 'react';
import { 
  Box, 
  Stepper, 
  Step, 
  StepLabel, 
  CircularProgress,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface LoadingStepsProps {
  currentStep: number;
}

const StepContainer = styled(Paper)(({ theme }) => ({
  margin: '24px 0',
  padding: '24px',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    '&.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
      fontWeight: 600,
    },
  },
}));

const LoadingSteps: React.FC<LoadingStepsProps> = ({ currentStep }) => {
  const steps = [
    'Fetching the Job Details',
    'Analyzing the Requirements',
    'Crafting Interview Questions'
  ];

  const StepIcon = ({ active, completed }: { active: boolean; completed: boolean }) => {
    if (completed) {
      return <CheckCircleIcon sx={{ color: 'success.main' }} />;
    }
    if (active) {
      return (
        <CircularProgress
          size={24}
          thickness={4}
          sx={{
            color: 'primary.main',
          }}
        />
      );
    }
    return (
      <Box
        sx={{
          width: 24,
          height: 24,
          border: '2px solid',
          borderColor: 'grey.300',
          borderRadius: '50%',
        }}
      />
    );
  };

  return (
    <StepContainer elevation={0}>
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel
              StepIconComponent={(props) => 
                StepIcon({
                  active: currentStep === index + 1,
                  completed: currentStep > index + 1,
                })}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </StepContainer>
  );
};

export default LoadingSteps;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import ResumeIcon from '@mui/icons-material/Assignment'; // Added for CV button
import { motion } from 'framer-motion';

export default function InputPage() {
  const navigate = useNavigate();
  const [inputMode, setInputMode] = useState<'url' | 'description'>('url');
  const [inputValue, setInputValue] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);

  const handleValidationErrorClose = () => {
    setValidationError(null);
  };

  const validateInput = (): boolean => {
    if (!inputValue.trim()) {
      setValidationError(inputMode === 'url' ? 'Please enter a URL' : 'Please enter a job description');
      return false;
    }

    if (inputMode === 'url') {
      if (!inputValue.match(/^https?:\/\//)) {
        setValidationError('URL must start with http:// or https://');
        return false;
      }
      try {
        new URL(inputValue);
      } catch {
        setValidationError('Please enter a valid URL (e.g., https://www.example.com/job)');
        return false;
      }
    } else if (inputValue.trim().length < 50) {
      setValidationError('Please provide a more detailed job description (minimum 50 characters)');
      return false;
    }

    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    
    if (validationError) {
      setValidationError(null);
    }
    
    if (inputMode === 'url' && value.trim() && !value.match(/^https?:\/\//)) {
      setValidationError('URL must start with http:// or https://');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateInput()) {
      return;
    }

    navigate('/analysis', { 
      state: { 
        inputMode, 
        inputValue 
      }
    });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        py: 8,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography 
          variant="h3" 
          component="h1" 
          align="center" 
          gutterBottom
          sx={{ 
            fontWeight: 700,
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            mb: 4
          }}
        >
          Prep4Job Interview Questions
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Box sx={{ display: 'flex', mb: 4, justifyContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  position: 'relative',
                  bgcolor: 'grey.100',
                  borderRadius: '28px',
                  overflow: 'hidden',
                  width: '300px',
                  height: '36px',
                }}
              >
                <motion.div
                  className="slider"
                  initial={false}
                  animate={{
                    x: inputMode === 'url' ? 0 : '100%',
                  }}
                  transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    borderRadius: '28px',
                    backgroundColor: '#1976d2',
                    zIndex: 0,
                    width: '150px',
                  }}
                />
                <Button
                  onClick={() => setInputMode('url')}
                  startIcon={<LinkIcon />}
                  sx={{
                    height: '36px',
                    borderRadius: '28px',
                    fontSize: '0.875rem',
                    textTransform: 'none',
                    width: '150px',
                    color: inputMode === 'url' ? 'white' : 'text.secondary',
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                    zIndex: 1,
                  }}
                >
                  URL
                </Button>
                <Button
                  onClick={() => setInputMode('description')}
                  startIcon={<DescriptionIcon />}
                  sx={{
                    height: '36px',
                    borderRadius: '28px',
                    fontSize: '0.875rem',
                    textTransform: 'none',
                    width: '150px',
                    color: inputMode === 'description' ? 'white' : 'text.secondary',
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                    zIndex: 1,
                  }}
                >
                  Description
                </Button>
              </Box>
            </Box>

            <TextField
              fullWidth
              multiline={inputMode === 'description'}
              rows={inputMode === 'description' ? 4 : 1}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={inputMode === 'url' ? 'Enter job description URL' : 'Paste job description here'}
              variant="outlined"
              error={!!validationError}
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  bgcolor: 'grey.50',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    bgcolor: 'grey.100',
                  },
                  '&.Mui-focused': {
                    bgcolor: 'white',
                    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                  },
                },
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{
                mb: 2,
                py: 2,
                borderRadius: '12px',
                fontSize: '1.1rem',
                fontWeight: 600,
                textTransform: 'none',
                bgcolor: '#1976d2',
                '&:hover': {
                  bgcolor: '#1565c0',
                },
              }}
            >
              Get Questions Now
            </Button>

            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Button
                onClick={() => navigate('/resume')}
                startIcon={<ResumeIcon />}
                variant="outlined"
                fullWidth
                sx={{
                  py: 1.5,
                  borderRadius: '12px',
                  fontSize: '1rem',
                  textTransform: 'none',
                  color: '#2196F3',
                  borderColor: '#2196F3',
                  '&:hover': {
                    bgcolor: 'rgba(33, 150, 243, 0.04)',
                    borderColor: '#1976d2',
                  },
                }}
              >
                Create a Tailored CV for this job
              </Button>
            </motion.div>
          </Box>
        </Paper>

        <Snackbar
          open={!!validationError}
          autoHideDuration={3000}
          onClose={handleValidationErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleValidationErrorClose}
            severity="warning"
            variant="filled"
            sx={{
              width: '100%',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          >
            {validationError}
          </Alert>
        </Snackbar>
      </motion.div>
    </Container>
  );
}
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Snackbar, Alert, Button, CircularProgress, TextField, IconButton } from '@mui/material';
import { Add, Delete } from "@mui/icons-material";
import { motion } from 'framer-motion';
import LoadingSteps from '../components/LoadingSteps';
import { useSubmitResume } from '../hooks/useSubmitResume';
import { useSubmitResume2 } from '../hooks/useSubmitResume2';

interface Experience {
    duration: string;
    jobTitle: string;
    company: string;
    description: string;
  }
  
  interface Education {
    year: string;
    degree: string;
    institution: string;
  }
  
  interface Language {
    name: string;
    proficiency: string;
  }
  
  interface Resume {
    fullName: string;
    contactInfo: string;
    summary: string;
    workExperiences: Experience[];
    educations: Education[];
    languages: Language[];
    references: string;
  }

export default function ResumePage2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { submitResume, isSubmitting, error, setError } = useSubmitResume2();

  const [resumeData, setResumeData] = useState<Resume>({
    fullName: "",
    contactInfo: "",
    summary: "",
    workExperiences: [],
    educations: [],
    languages: [],
    references: "",
  });

    const [validationError, setValidationError] = useState<string | null>(null);

    const handleValidationErrorClose = () => {
        setValidationError(null);
    };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 2000);
    }
  }, [error, setError]);

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setResumeData({ ...resumeData, [e.target.name]: e.target.value });
//   };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setResumeData((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayChange = (index: number, field: string, value: string, key: keyof Resume) => {
    setResumeData((prev) => {
      const updatedArray = [...(prev[key] as any[])];
      updatedArray[index] = { ...updatedArray[index], [field]: value };
      return { ...prev, [key]: updatedArray };
    });
  };
  
  const addArrayItem = (key: keyof Resume, newItem: any) => {
    setResumeData((prev) => ({
      ...prev,
      [key]: [...(prev[key] as any[]), newItem],
    }));
  };
  
  const removeArrayItem = (key: keyof Resume, index: number) => {
    setResumeData((prev) => {
      const updatedArray = [...(prev[key] as any[])];
      updatedArray.splice(index, 1);
      return { ...prev, [key]: updatedArray };
    });
  };

  const validateInput = (): boolean => {
    if (!resumeData.fullName.trim()) {
      setValidationError('Please enter your fullname');
      return false;
    }

    if (!resumeData.contactInfo.trim()) {
      setValidationError('Please enter your contact info');
      return false
    }

    if (!resumeData.summary.trim()) {
      setValidationError('Please enter the summary');
      return false
    }

    if (!resumeData.references.trim()) {
      setValidationError('Please enter some references');
      return false
    }

    if (!resumeData.languages || resumeData.languages.length == 0) {
      setValidationError('Please enter languages you know');
      return false
    }

    if (!resumeData.languages || resumeData.languages.length == 0) {
        setValidationError('Please enter languages you know');
        return false;
    }

    if (resumeData.languages.length > 0) {
      resumeData.languages.forEach(lang => {
        if (!lang.name.trim() || !lang.proficiency.trim()) {
          setValidationError('Please enter all the fields on the language');
        }
      });
    }

    if (resumeData.workExperiences && resumeData.workExperiences.length > 0) {
      resumeData.workExperiences.forEach(exp => {
        if (!exp.company.trim() || !exp.duration.trim() || !exp.jobTitle.trim() || !exp.description.trim()) {
          setValidationError('Please enter all the fields on the work experience');
          return false;
        }
      });
    }

    if (resumeData.educations && resumeData.educations.length > 0) {
      resumeData.educations.forEach(edu => {
        if (!edu.year.trim() || !edu.degree.trim() || !edu.institution.trim()) {
          setValidationError('Please enter all the fields on the education');
          return false;
        }
      });
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateInput()) {
        return;
    }

    const pdfBlob = await submitResume(resumeData);
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'resume.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        py: 8,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
            Resume Form
            </Typography>
            <TextField fullWidth name="fullName" label="Full Name" margin="normal" onChange={handleChange} />
            <TextField fullWidth name="contactInfo" label="Contact Info" margin="normal" onChange={handleChange} />
            <TextField fullWidth multiline rows={4} name="summary" label="Summary" margin="normal" onChange={handleChange} />
            
            <Typography variant="h6">Work Experience</Typography>
            {resumeData.workExperiences.map((exp, index) => (
            <Box key={index}>
                <TextField fullWidth label="Duration" value={exp.duration} onChange={(e) => handleArrayChange(index, "duration", e.target.value, "workExperiences")} margin="normal" />
                <TextField fullWidth label="Job Title" value={exp.jobTitle} onChange={(e) => handleArrayChange(index, "jobTitle", e.target.value, "workExperiences")} margin="normal" />
                <TextField fullWidth label="Company" value={exp.company} onChange={(e) => handleArrayChange(index, "company", e.target.value, "workExperiences")} margin="normal" />
                <TextField fullWidth label="Description" value={exp.description} onChange={(e) => handleArrayChange(index, "description", e.target.value, "workExperiences")} margin="normal" />
                <IconButton onClick={() => removeArrayItem("workExperiences", index)}><Delete /></IconButton>
            </Box>
            ))}
            <Button startIcon={<Add />} onClick={() => addArrayItem("workExperiences", { duration: "", jobTitle: "", company: "", responsibilities: "" })}>Add Experience</Button>
            
            <Typography variant="h6">Education</Typography>
            {resumeData.educations.map((edu, index) => (
            <Box key={index}>
                <TextField fullWidth label="Year" value={edu.year} onChange={(e) => handleArrayChange(index, "year", e.target.value, "educations")} margin="normal" />
                <TextField fullWidth label="Degree" value={edu.degree} onChange={(e) => handleArrayChange(index, "degree", e.target.value, "educations")} margin="normal" />
                <TextField fullWidth label="Institution" value={edu.institution} onChange={(e) => handleArrayChange(index, "institution", e.target.value, "educations")} margin="normal" />
                <IconButton onClick={() => removeArrayItem("educations", index)}><Delete /></IconButton>
            </Box>
            ))}
            <Button startIcon={<Add />} onClick={() => addArrayItem("educations", { year: "", degree: "", institution: "" })}>Add Education</Button>
            
            <Typography variant="h6">Languages</Typography>
            {resumeData.languages.map((lang, index) => (
            <Box key={index}>
                <TextField fullWidth label="Language" value={lang.name} onChange={(e) => handleArrayChange(index, "name", e.target.value, "languages")} margin="normal" />
                <TextField fullWidth label="Proficiency" value={lang.proficiency} onChange={(e) => handleArrayChange(index, "proficiency", e.target.value, "languages")} margin="normal" />
                <IconButton onClick={() => removeArrayItem("languages", index)}><Delete /></IconButton>
            </Box>
            ))}
            <Button startIcon={<Add />} onClick={() => addArrayItem("languages", { name: "", proficiency: "" })}>Add Language</Button>
            
            <TextField fullWidth multiline rows={2} name="references" label="References" margin="normal" onChange={handleChange} />
            <Button type="submit" variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Submit
            </Button>
        </Box>

        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            variant="filled"
            sx={{ width: '100%', boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)' }}
          >
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
            open={!!validationError}
            autoHideDuration={3000}
            onClose={handleValidationErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
            onClose={handleValidationErrorClose}
            severity="warning"
            variant="filled"
            sx={{
                width: '100%',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
            >
            {validationError}
            </Alert>
        </Snackbar>
      </motion.div>
    </Container>
  );
}

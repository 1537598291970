import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import InputPage from './pages/InputPage';
import AnalysisPage from './pages/AnalysisPage';
import QuestionsPage from './pages/QuestionsPage';
import ResumePage from './pages/ResumePage';
import ResumePage2 from './pages/ResumePage2';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<InputPage />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/questions" element={<QuestionsPage />} />
          {/* <Route path="/resume" element={<ResumePage />} /> */}
          <Route path="/resume" element={<ResumePage2 />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Paper,
  Modal,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import LoadingSteps from '../components/LoadingSteps';
import { useJobQuestions } from '../hooks/useJobQuestions';

interface LocationState {
  inputMode: 'url' | 'description';
  inputValue: string;
}

export default function AnalysisPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentStep, error, setError, processJobInput } = useJobQuestions();

  // Handle error state changes
  useEffect(() => {
    if (error) {
      // Redirect to home page after 2 seconds when there's an error
      const timer = setTimeout(() => {
        navigate('/');
        setError(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error, navigate, setError]);

  useEffect(() => {
    const state = location.state as LocationState;
    if (!state?.inputValue) {
      navigate('/');
      return;
    }

    const processInput = async () => {
      const result = await processJobInput('description', state.inputValue);
      if (result) {
        navigate('/questions', { state: result });
      }
    };

    processInput();
  }, [location.state, navigate, processJobInput]);

  return (
    <Container
      maxWidth="md"
      sx={{
        py: 8,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            textAlign: 'center',
          }}
        >
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              mb: 4
            }}
          >
            Analyzing Job Description
          </Typography>

          <LoadingSteps currentStep={currentStep} />
        </Paper>

        <Snackbar
          open={!!error}
          autoHideDuration={2000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            variant="filled"
            sx={{
              width: '100%',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      </motion.div>
    </Container>
  );
}

const DEFAULT_API_URL = 'https://api.prep4job.com/api';

function getApiBaseUrl(): string {
  // Try to get from environment variable
  const envUrl = process.env.VITE_API_BASE_URL;
  if (envUrl) {
    return envUrl;
  }
  
  // Fallback to default
  console.warn('API URL not found in environment variables, using default:', DEFAULT_API_URL);
  return DEFAULT_API_URL;
}

export const config = {
  apiBaseUrl: getApiBaseUrl()
} as const;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Divider,
  Snackbar,
  IconButton,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuestionList from '../components/QuestionList';

interface LocationState {
  questions: string[];
  jobDescription: string;
}

export default function QuestionsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<{ text: string; severity: 'success' | 'error' }>({ text: '', severity: 'success' });

  // Redirect to home if no questions are available
  if (!state?.questions) {
    navigate('/');
    return null;
  }

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Interview Questions',
        text: state.questions.join('\n\n'),
      });
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(state.questions.join('\n\n'));
      setSnackbarMessage({ text: 'Questions copied to clipboard!', severity: 'success' });
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error copying:', err);
      setSnackbarMessage({ text: 'Failed to copy questions', severity: 'error' });
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/')}
              sx={{
                textTransform: 'none',
                fontWeight: 500,
              }}
            >
              New Questions
            </Button>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <IconButton
                onClick={handleCopy}
                color="primary"
                aria-label="copy to clipboard"
                size="large"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(33, 150, 243, 0.08)',
                  },
                }}
              >
                <ContentCopyIcon />
              </IconButton>
              <Button
                startIcon={<ShareIcon />}
                onClick={handleShare}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                }}
              >
                Share Questions
              </Button>
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{
              p: 4,
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          >
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom
              sx={{ 
                fontWeight: 700,
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                mb: 2
              }}
            >
              Your Interview Questions
            </Typography>

            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ mb: 4 }}
            >
              Here are your tailored interview questions based on the job description. Practice these to prepare for your interview!
            </Typography>

            <Divider sx={{ mb: 4 }} />

            <QuestionList questions={state.questions} />
          </Paper>
        </motion.div>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbarMessage.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}

import React from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  Typography,
  Paper,
  Fade,
  styled
} from '@mui/material';
import { motion } from 'framer-motion';

interface QuestionListProps {
  questions: string[];
}

const QuestionList: React.FC<QuestionListProps> = ({ questions }) => {
  return (
    <Fade in={questions.length > 0} timeout={500}>
      <Paper
        elevation={0}
        sx={{
          padding: { xs: 2, md: 3 },
          marginTop: 3,
          borderRadius: 2,
          bgcolor: 'background.paper',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          },
        }}
      >
        <List disablePadding>
          {questions.map((question, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <Box
                sx={{
                  p: 2,
                  mb: 1,
                  borderRadius: 1,
                  bgcolor: 'grey.50',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    bgcolor: 'grey.100',
                    transform: 'scale(1.01)',
                  },
                  '&:last-child': {
                    mb: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Typography
                    sx={{
                      minWidth: '28px',
                      height: '28px',
                      borderRadius: '14px',
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 1.5,
                      fontSize: '0.875rem',
                      fontWeight: 600,
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.primary',
                      lineHeight: 1.6,
                      flex: 1,
                    }}
                  >
                    {question}
                  </Typography>
                </Box>
              </Box>
            </motion.div>
          ))}
        </List>
      </Paper>
    </Fade>
  );
};

export default QuestionList;

import { useState } from 'react';
import axios from 'axios';
import { config } from '../config';

// Static flag to prevent duplicate requests across re-renders
let isProcessing = false;

export const useJobQuestions = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState<string | null>(null);

  const processJobInput = async (inputMode: 'url' | 'description', inputValue: string) => {
    if (isProcessing) {
      return null;
    }

    isProcessing = true;
    try {
      let jobText: string;

      if (inputMode === 'url') {
        const scrapeResponse = await axios.post(
          `${config.apiBaseUrl}/scrape`,
          { url: inputValue },
          { headers: { 'Content-Type': 'application/json' } }
        );
        jobText = scrapeResponse.data.text;
      } else {
        jobText = inputValue;
      }

      setCurrentStep(2);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setCurrentStep(3);

      const questionsResponse = await axios.post(
        `${config.apiBaseUrl}/questions`,
        { text: jobText },
        { headers: { 'Content-Type': 'application/json' } }
      );

      return {
        questions: questionsResponse.data.questions,
        jobDescription: jobText
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          setError('Could not find the job posting. Please check the URL and try again.');
        } else {
          setError('Failed to generate questions. Please try again later.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.: ' + err);
      }
      return null;
    } finally {
      isProcessing = false;
    }
  };

  return {
    currentStep,
    error,
    setError,
    processJobInput
  };
};
